import React from "react"
import styles from "./advantages.module.scss"
import { openOverlay } from "../../../../../utils/overlay"

const HeroFeatureIndexPage = ({ title, description }) => (
  <div className={styles.hero}>

    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}>
        {title}
      </h1>
      <p className={styles.subheading}>{description}</p>
    </div>

  </div>
)

export default HeroFeatureIndexPage

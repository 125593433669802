import React from "react"
import SEO from "../components/seo"
import HeroPage from "../components/pages/shared/hero/advantages"
import CrmBanner from "../components/pages/advantages/banner"
import ContactFormComponent from "../components/pages/shared/contact-form"
import Layout from "../components/layout"

import TargetLangingsComponent from "../components/pages/advantages/target-landings"
import AdvantagesDesk from "../components/pages/advantages/anvantages-desk"
import Breadcrumb from '../components/controls/breadcrumbs';

const AdvantagesPage = (props) => {
    const crumbs = [
        { name: 'Главная', url: '/'},
        { name: 'Преимущества', url: ''},
    ];
    return (
      <Layout headerColor={"#6d20d2"} page={"advantages"}>
          <SEO title="Преимущества Параплан CRM" keywords={[`crm-система`, `преимущества`]} url={"https://paraplancrm.ru" + props.location.pathname}
               description="Рассказываем о преимуществах CRM-системы Параплан. Современные технологии, экономия, быстрая техническая поддержка и многое другое."
          />
          <HeroPage
            title='Преимущества Параплан CRM'
            description='Узнайте, почему 40% клиентов работает с нами больше года'
            color='#6d20d2'
          />
          <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
          <CrmBanner />
          <AdvantagesDesk />
          <TargetLangingsComponent title="Выберите свое направление бизнеса" id="advantages" />
          <ContactFormComponent />
      </Layout>
    )
}

export default AdvantagesPage
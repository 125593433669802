import React from "react"
import styles from "./advantages-desk.module.scss"
import AdvantagesCard from "../advantages-card"
import { openOverlay } from "../../../../utils/overlay"

const AdvantagesDesk = () => (
  <div className={'container'}>
    <div className={styles.descWrapper}>
      <h2 className={styles.deskTitle}>
        <span style={{color: '#6D20D2'}}>6 причин</span> почему Параплан подходит современному бизнесу
      </h2>
      <div className={`${styles.sectionWrapper} ${styles.purple}`}>
        <div className={styles.infoColumn}>
          <div className={styles.headSectionWrapper}>
            <div className={styles.num}>1</div>
            <div className={styles.line}> </div>
            <h3 className={styles.sectionTitle}>
              Вы получаете надежный сервис
            </h3>
          </div>
            <div className={styles.cardsWrapper}>
              <AdvantagesCard
                descr={"Инфраструктура на территории РФ"}
                image={require("../../../../images/advantages/icon11.svg")}
              />
              <AdvantagesCard
                descr={"Продукт российской <br/> <a target='_blank' href='/about'><nobr>IT-компании</nobr>&nbsp;HAULMONT</a>"}
                image={require("../../../../images/advantages/Icon12.svg")}
              />
              <AdvantagesCard
                descr={"Регистрация в <a target='_blank' href='https://paraplancrm.ru/blog/rosreestr-paraplan-crm'>Реестре отечественного ПО</a>"}
                image={require("../../../../images/advantages/Icon13.svg")}
              />
              <AdvantagesCard
                descr={"<strong>№1 в рейтинге CRM</strong> для детских центров и франшиз*"}
                image={require("../../../../images/advantages/Icon14.svg")}
              />
            </div>
        </div>
        <div className={styles.imgColumn}>
          <img src={require("../../../../images/advantages/section1.svg")} alt={"Вы получаете надежный сервис"} />
        </div>
      </div>
      <div className={styles.prompt}>* По данным сервиса CRMindex в рейтинге лучших «CRM для детских центров», «CRM для франшиз».</div>

      <div className={`${styles.sectionWrapper} ${styles.blue}`}>
        <div className={styles.imgColumn}>
          <img src={require("../../../../images/advantages/section2.svg")} alt={"Вам не требуется обучение"} />
        </div>
        <div className={styles.infoColumn}>
          <div className={styles.headSectionWrapper}>
            <div className={styles.num}>2</div>
            <div className={styles.line}> </div>
            <h3 className={styles.sectionTitle}>
              Вам не требуется обучение
            </h3>
          </div>
          <div className={styles.cardsWrapper}>
            <AdvantagesCard
              descr={"Бесплатный тест системы 10 дней"}
              image={require("../../../../images/advantages/icon21.svg")}
              modal={true}
            />
            <AdvantagesCard
              descr={"Старт работы в CRM за 1 час"}
              image={require("../../../../images/advantages/icon22.svg")}
            />
            <AdvantagesCard
              descr={"Понятное расположение элементов"}
              image={require("../../../../images/advantages/icon23.svg")}
            />
            <AdvantagesCard
              descr={"Бесплатный перенос базы клиентов и лидов"}
              image={require("../../../../images/advantages/icon24.svg")}
            />
          </div>
        </div>
      </div>

      <div className={`${styles.sectionWrapper} ${styles.green}`}>
        <div className={styles.infoColumn}>
          <div className={styles.headSectionWrapper}>
            <div className={styles.num}>3</div>
            <div className={styles.line}> </div>
            <h3 className={styles.sectionTitle}>
              Вы используете современные технологии
            </h3>
          </div>
          <div className={styles.cardsWrapper}>
            <AdvantagesCard
              descr={"Учет потребностей малого и среднего бизнеса"}
              image={require("../../../../images/advantages/icon31.svg")}
            />
            <AdvantagesCard
              descr={"Полный набор интеграций (мессенджеры, онлайн-касса, IP-телефония и т.д.)"}
              image={require("../../../../images/advantages/icon32.svg")}
            />
            <AdvantagesCard
              descr={"Исключение электронных таблиц"}
              image={require("../../../../images/advantages/icon33.svg")}
            />
            <AdvantagesCard
              descr={"Внедрение без разработчика"}
              image={require("../../../../images/advantages/icon34.svg")}
            />
          </div>
        </div>
        <div className={styles.imgColumn}>
          <img src={require("../../../../images/advantages/section3.svg")} alt={"Вы используете современные технологии"} />
        </div>
      </div>

      <div className={`${styles.sectionWrapper} ${styles.orange}`}>
        <div className={styles.imgColumn}>
          <img src={require("../../../../images/advantages/section4.svg")} alt={"Вы экономите деньги"} />
        </div>
        <div className={styles.infoColumn}>
          <div className={styles.headSectionWrapper}>
            <div className={styles.num}>4</div>
            <div className={styles.line}> </div>
            <h3 className={styles.sectionTitle}>
              Вы экономите деньги
            </h3>
          </div>
          <div className={styles.cardsWrapper}>
            <AdvantagesCard
              descr={"Стоимость подписки <strong>от 802 руб./мес.</strong>"}
              image={require("../../../../images/advantages/icon41.svg")}
            />
            <AdvantagesCard
              descr={"Гибкая и прозрачная <a target='_blank' href='https://paraplancrm.ru/pricing'>тарифная сетка</a>"}
              image={require("../../../../images/advantages/icon42.svg")}
            />
            <AdvantagesCard
              descr={"<strong>Скидки до 25%</strong> новым клиентам"}
              image={require("../../../../images/advantages/icon43.svg")}
            />
            <AdvantagesCard
              descr={"Акции на продление подписки"}
              image={require("../../../../images/advantages/icon44.svg")}
            />
          </div>
        </div>
      </div>

      <div className={`${styles.sectionWrapper} ${styles.blue}`}>
        <div className={styles.infoColumn}>
          <div className={styles.headSectionWrapper}>
            <div className={styles.num}>5</div>
            <div className={styles.line}> </div>
            <h3 className={styles.sectionTitle}>
              Вы не привязаны к рабочему месту
            </h3>
          </div>
          <div className={styles.cardsWrapper}>
            <AdvantagesCard
              descr={"Для работы нужен только Интернет"}
              image={require("../../../../images/advantages/icon51.svg")}
            />
            <AdvantagesCard
              descr={"Адаптированная <a target='_blank' href='https://paraplancrm.ru/feature/mobile'>мобильная версия</a>"}
              image={require("../../../../images/advantages/icon52.svg")}
            />
            <AdvantagesCard
              descr={"Доступ к системе из любой точки мира"}
              image={require("../../../../images/advantages/icon53.svg")}
            />
            <AdvantagesCard
              descr={"Личный кабинет клиента и сотрудника"}
              image={require("../../../../images/advantages/icon54.svg")}
            />
          </div>
        </div>
        <div className={styles.imgColumn}>
          <img src={require("../../../../images/advantages/section5.svg")} alt={"Вы не привязаны к рабочему месту"} />
        </div>
      </div>

      <div className={`${styles.sectionWrapper} ${styles.powder}`}>
        <div className={styles.imgColumn}>
          <img src={require("../../../../images/advantages/section6.svg")} alt={"Вам помогают профессионалы"} />
        </div>
        <div className={styles.infoColumn}>
          <div className={styles.headSectionWrapper}>
            <div className={styles.num}>6</div>
            <div className={styles.line}> </div>
            <h3 className={styles.sectionTitle}>
              Вам помогают профессионалы
            </h3>
          </div>
          <div className={styles.cardsWrapper}>
            <AdvantagesCard
              descr={"Все специалисты работают в Параплан больше года"}
              image={require("../../../../images/advantages/icon61.svg")}
            />
            <AdvantagesCard
              descr={"Полное сопровождение на этапе сделки"}
              image={require("../../../../images/advantages/icon62.svg")}
            />
            <AdvantagesCard
              descr={"Среднее время ответа тех. поддержки 15 минут"}
              image={require("../../../../images/advantages/icon63.svg")}
            />
            <AdvantagesCard
              descr={"Бесплатная презентация системы"}
              image={require("../../../../images/advantages/icon64.svg")}
            />
          </div>
        </div>
      </div>

    </div>
  </div>
)

export default AdvantagesDesk
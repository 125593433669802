import React from "react"
import styles from "./advantages-card.module.scss"
import { openOverlay } from "../../../../utils/overlay"

const AdvantageCard = ({ image, descr, modal}) => (
  <div className={styles.advantage_card}>
    <div className={styles.image}>
      <img src={image} />
    </div>
    {modal ? <a href='#' className={styles.descr} onClick={() => openOverlay('free-summer', 'action', 'trial')}>Бесплатный тест системы 10 дней</a> : <div className={styles.descr} dangerouslySetInnerHTML={{ __html: descr }} />}

  </div>
)
export default AdvantageCard
import React from "react"
import { Link } from "gatsby"

import styles from "./target-landings-card.module.scss"
import PropTypes from "prop-types"

const TargetAudienceCard = ({ image, title, backgroundColor, link }) => (
  <Link to={link} className={`${styles.target_audience_card}`}>
    <div
      className={styles.image}
      style={{ backgroundColor }}>
      <img alt={title} src={image} />
    </div>
    <div className={styles.title}>{title}</div>
  </Link>
)

TargetAudienceCard.propTypes = {
  backgroundColor: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  link: PropTypes.node.isRequired,
}

export default TargetAudienceCard
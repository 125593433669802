import React from "react"
import styles from "./banner.module.scss"
import { openOverlay } from "../../../../utils/overlay"
import ScrollAnimation from "react-animate-on-scroll"

const CrmBanner = () => (
  <div className={'container'}>
    <div className={styles.wrapper}>
      <div className={styles.title}>Работа в CRM-системе затягивает,
        стоит только нажать кнопку
      </div>
      <div className={styles.ctaContainer}>
          <button className={`${styles.cta}`} onClick={() => openOverlay('free-summer', "action", "trial")}>
            Зарегистрироваться
          </button>
          <ScrollAnimation animateIn={"animate-tada"} duration={1}>
            <div className={styles.arrow} />
          </ScrollAnimation>
      </div>
    </div>
  </div>
)
export default CrmBanner